<template>
  <div>
    <div class="centerNav">
      <a :class="{active: section === 'hammer'}" href="#" @click="section = 'hammer'">
        <h2>Level Design</h2>
      </a>
      <a :class="{active: section === 'lua'}" href="#" @click="section = 'lua'">
        <h2>G-LUA</h2>
      </a>
    </div>
    <template v-if="section === 'lua'">
      <h2>Wsky's Lootboxes - <a href="https://discord.com/invite/APtzaJQ">Join our Discord</a></h2>
      <div class="card">
        <p>A custom made addon for our own TTT server, which gives frequently players to chance to win weapon/playerModel crates at the end of each round.</p>
        <p>Players can also scrap these items or trade them with each other on the market.</p>
        <p>Collected scrap can then be used to buy new crates from the store, or randomly generated items from the daily shop.</p>
        <div id="wsky_lootboxes">
          <div
            v-for="(media, index) in getMedia('wsky_lootboxes')"
            :key="media.src + index"
            :class="index % 2 ? 'even' : 'odd'"
            style="display: inline"
          >
            <a v-if="media.type === 'image'" href="#" @click="previewSrc = media.src; previewType = media.type">
              <img :src="media.src">
            </a>
          </div>
        </div>
      </div>
      <h2>One versus One [OvO] - <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2189395411">Workshop Link</a></h2>
      <div class="card">
        <div style="word-wrap: unset;">
          <p>A gamemode designed for players to practise and test their skills, or just have a bit of fun.</p>
          <p>Fast paced gameplay, combined loadouts, and unique ‘Spectre’ ability.</p>
        </div>
        <div id="wsky_ovo">
          <div
            v-for="(media, index) in getMedia('wsky_ovo')"
            :key="media.src + index"
            :class="index % 2 ? 'even' : 'odd'"
            style="display: inline"
          >
            <a href="#" @click="previewSrc = media.src; previewType = media.type">
              <img :src="media.type === 'video' ? media.thumb : media.src">
            </a>
          </div>
        </div>
      </div>
      <h2>Wsky DarkRP Vending Machine - <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2216396020">Workshop Content Pack Link</a></h2>

      <div class="card">
        <p>When I first started this project, there wasn't anything that really satisified what I was looking for. I wanted a vending machine with a global market, where anyone could buy or sell their items to each other, and for it to work even if the player was offline.</p>
        <p>Since starting and working on this, a number of addons have popped up serving either nearly the same purpose and existing ones have been updated to support this.</p>
        <p>This has just been sat in my github ever since, not being used. It was one of my earlier projects, and honestly could do with a refactor. But honestly, I might just make it open source (including the custom model) and let someone else take it over, if they want it.</p>
        <div id="wsky_vendingmachine">
          <div
            v-for="(media, index) in getMedia('wsky_vendingmachine')"
            :key="media.src + index"
            :class="index % 2 ? 'even' : 'odd'"
            style="display: inline"
          >
            <a href="#" @click="previewSrc = media.src; previewType = media.type">
              <img :src="media.type === 'video' ? media.thumb : media.src">
            </a>
          </div>
        </div>
      </div>
      <h2>Wsky's FastTravel</h2>
      <div class="card">
        <p>I'm lazy, alright? Having to walk from one side of a large map to another sucks, especially if you're on a server/gamemode that incentivizes killing/mugging.</p>
        <p>So, I thought <q>Hey, why don't I create like a bus stop or taxi system?</q> and that's exactly what I set out to do.</p>
        <p>
          <em>or at least, was doing till my damn blender model kept turning itself inside out in-game, and I got frustrated and moved
            onto something else.</em>
        </p>
        <div id="wsky_fasttravel">
          <div
            v-for="(media, index) in getMedia('wsky_fasttravel')"
            :key="media.src + index"
            :class="index % 2 ? 'even' : 'odd'"
            style="display: inline"
          >
            <a href="#" @click="previewSrc = media.src; previewType = media.type">
              <img :src="media.type === 'video' ? media.thumb : media.src">
            </a>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <h2>rp_scp_tophat - <em>Postponed</em></h2>
      <div class="card">
        <p>A map being developed for TopHat's upcoming SCP:RP roleplay server.</p>
        <p>Designed by myself, inspired by mostly every scifi underground laboratory I could think of.</p>
        <p><em>This map has been postponed given that development of S&Box is underway, because we'll most likely move SCP:RP there.</em></p>
        <div id="rp_scp_tophat_images">
          <div
            v-for="(media, index) in getMedia('rp_scp_tophat_images')"
            :key="media.src + index"
            :class="index % 2 ? 'even' : 'odd'"
            style="display: inline"
          >
            <a href="#" @click="previewSrc = media.src; previewType = media.type">
              <img :src="media.type === 'video' ? media.thumb : media.src">
            </a>
          </div>
        </div>
      </div>
      <h2>de_complex - <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2054401384">Workshop Link</a></h2>
      <div class="card">
        <p>A CS:GO competitve map developed during the first covid lockdown, more as a way to pass the time than anything. Was put on hold originally but has since been considered abandoned till futher notice.</p>
        <p>Originally inspired by a school I once attended, but of course drastically altered afterwards to avoid it being indentical, so that <a href="https://www.theregister.com/2007/05/03/student_counterstrike_map_texasschool/">I'm not branded as a terrorist.</a></p>
        <div id="de_complex_images">
          <div
            v-for="(media, index) in getMedia('de_complex_images')"
            :key="media.src + index"
            :class="index % 2 ? 'even' : 'odd'"
            style="display: inline"
          >
            <a href="#" @click="previewSrc = media.src; previewType = media.type">
              <img :src="media.type === 'video' ? media.thumb : media.src">
            </a>
          </div>
        </div>
      </div>
      <h2>rp_downtown_remastered</h2>
      <div class="card">
        <p>A community I was a part of wanted a remake of <code>rp_downtown_tits_v2</code>, I created this as a proof of concept to submit to them.</p>
        <p>They decided not to choose me, so I didn't continue development. I might return in the future, but if you want access you can find the source files on my github below.</p>
        <p><a href="https://github.com/WhiskeeDev/rp_downtown_remastered">https://github.com/WhiskeeDev/rp_downtown_remastered</a></p>
        <p><em>NOTE: This requires custom textures... and I misplaced them... sorry. When I find them I'll add them to the github</em></p>
        <div id="rp_scp_tophat_images">
          <div
            v-for="(media, index) in getMedia('rp_downtown_remastered')"
            :key="media.src + index"
            :class="index % 2 ? 'even' : 'odd'"
            style="display: inline"
          >
            <a href="#" @click="previewSrc = media.src; previewType = media.type">
              <img :src="media.type === 'video' ? media.thumb : media.src">
            </a>
          </div>
        </div>
      </div>
    </template>
    <div class="centerNav">
      <p><em>Don't forget to check the work in {{ section === 'hammer' ? 'G-LUA' : 'Level Design' }} by clicking below...</em></p>
      <a :class="{active: section === 'hammer'}" href="#" @click="section = 'hammer'">
        <h2>Level Design</h2>
      </a>
      <a :class="{active: section === 'lua'}" href="#" @click="section = 'lua'">
        <h2>G-LUA</h2>
      </a>
    </div>
    <image-preview v-model="previewSrc" :type="previewType" />
  </div>
</template>
<script>
import ImagePreview from '@/components/ImagePreview'

export default {
  components: { ImagePreview },
  data () {
    return {
      previewSrc: null,
      previewType: 'video',
      section: 'hammer',
      imageSections: {
        'wsky_lootboxes': {
          count: 4,
          folder: 'wsky_lootboxes'
        },
        'wsky_ovo': {
          count: 2,
          folder: 'wsky_ovo',
          videos: [
            'preview'
          ]
        },
        'wsky_vendingmachine': {
          count: 4,
          folder: 'wsky_vendingmachine'
        },
        'wsky_fasttravel': {
          count: 1,
          folder: 'wsky_fasttravel',
          videos: [
            'preview'
          ]
        },
        'rp_scp_tophat_images': {
          count: 8,
          folder: 'rp_scp_tophat'
        },
        'de_complex_images': {
          count: 6,
          folder: 'de_complex'
        },
        'rp_downtown_remastered': {
          count: 4,
          folder: 'rp_downtown_remastered'
        }
      }
    }
  },
  methods: {
    getMedia (id) {
      if (!id) return null

      let media = []
      for (let imageId = 1; imageId <= this.imageSections[id].count; imageId++) {
        // console.log(`./assets/images/${this.imageSections[id].folder}/${imageId}.jpg`)
        media.push({
          type: 'image',
          src: require(`@/assets/images/${this.imageSections[id].folder}/${imageId}.jpg`)
        })
      }

      if (this.imageSections[id].videos) {
        this.imageSections[id].videos.forEach(videoId => {
          media.push({
            type: 'video',
            src: require(`@/assets/images/${this.imageSections[id].folder}/${videoId}.mp4`),
            thumb: require(`@/assets/images/${this.imageSections[id].folder}/${videoId}.jpg`)
          })
        })
      }

      return media
    }
  }
}
</script>
